<template>
  <div class="container">
    <div>
      <p>Total kWh:</p>
      <h1> {{ total }} </h1>
    </div>
    <div>
      <p>Today kWh:</p>
      <h1> {{ today }} </h1>
    </div>
    <div>
      <p>Total Cost:</p>
      <h1> {{ totalCost }} €</h1>
    </div>
    <div>
      <p>Current power consumption:</p>
      <h1> {{ current }} W</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "Energy",
  data() {
    return {
      total: 0,
      today: 0,
      totalCost: 0,
      current: 0,
      timeout: null,
    }
  },
  mounted() {
    this.updateConsumption()
  },
  methods: {
    updateConsumption() {
      this.$store.state.ky.get('energy/currentConsumption').json().then(res => {
        this.current = res.power
        this.today = res.todayConsumption.toFixed(2)
        this.total = res.totalPowerConsumption.toFixed(2)
        this.totalCost = res.totalCost.toFixed(2)
        this.timeout = setTimeout(this.updateConsumption, 2000)
      })
    },
  },
  destroyed() {
    clearTimeout(this.timeout)
  },
}
</script>

<style scoped>
.container {
  width: 50rem;
  display: flex;
  border-radius: 1em;
}

.container div {
  background: white;
  padding: 0;
  flex: auto;
  text-align: center;
}

.container div p {
  font-size: 1em;
  font-weight: normal;
  color: black;
  margin: 0;
  padding: 0;
}

.container div h1 {
  margin: 0;
  padding: 0;
  font-size: 3em;
  font-weight: bold;
}
</style>